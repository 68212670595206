import { environment } from "@env/environment";
const HASH = environment.VIM_COOKIES_HASH;

export const Cookies = {
  TOKEN: HASH + "_COOKIE_0",
  TENANTID: HASH + "_COOKIE_1",
  TENANTDATA: HASH + "_COOKIE_2",
  ACTUAL_ROL: HASH + "_COOKIE_3",
  PDF_SCHEMAS: HASH + "_COOKIE_4",
  PDF_BASE: HASH + "_COOKIE_5",
};
