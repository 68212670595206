import { Component, inject, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MenuItem } from 'primeng/api';
import { TokenEntity } from '@data/entities';
import { CookieService } from 'ngx-cookie-service';
import { AuthService, Role } from '@app/core/services/auth/auth.service';
import { CasosService } from '@app/core/services/casos/casos.service';
import { UsersService } from '@app/core/services/api/user.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private router = inject(Router);
  private authService = inject(AuthService);
  private casosService = inject(CasosService);
  private cookieService = inject(CookieService);
  private userService = inject(UsersService);
  private token: TokenEntity;

  public isExpanded = false;
  public items: Array<MenuItem> = [];
  public display: boolean = false;
  public visible: boolean = false;
  public username: string = '';
  public currentTenant = this.authService.currentTenant;

  constructor() {
    this.token = new TokenEntity(this.cookieService);
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Hormiguitas',
        expanded: true,
        items: [
          {
            label: 'Inicio', routerLink: ['/home']
          },
          {
            label: 'Casos', routerLink: ['/casos']
          },
          {
            label: 'Proceso', routerLink: ['/procesos']
          },
          {
            label: 'Importar información', routerLink: ['/xlsx']
          },
        ]
      },
      {
        label: 'Configuración',
        expanded: true,
        visible: this.authService.role === Role.SUPER_ADMIN,
        items: [
          {
            label: 'Usuarios', routerLink: ['/users']
          },
        ]
      }
    ];
    this.username = this.token.decodedValue.userName;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public logOut(event: boolean) {
    if (event === false) {
      this.visible = !this.visible;
    } else {
      this.authService.logout();
    }
  }

  get currentTenantName() {
    return this.authService.getTenantName();
  }

  get currentUserEmail() {
    return ''
    // return this.authService.email;
  }

  get userTenants() {
    return this.authService.tenants;
  }

  get casosSearchString() {
    return this.casosService.currentSearchString
  };

  onBuscar(event: any) {
    const searchString = event.target.value;
    this.router.navigate(['/casos/buscar', searchString]);
  }

  async onTenantChange(event: any) {
    this.authService.setCurrentTenant(event.value);
    if (!event.value) return;
    await firstValueFrom(this.userService.setCurrentTenant(event.value));
    this.authService.refreshUserToken();    
  }
}