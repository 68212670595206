import { NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { ToolbarModule } from 'primeng/toolbar';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { SkeletonModule } from 'primeng/skeleton';
import { BlockUIModule } from 'primeng/blockui';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TagModule } from 'primeng/tag';

@NgModule({
    declarations: [],
    imports: [
        InputTextModule,
        CardModule,
        ButtonModule,
        SidebarModule,
        ToolbarModule,
        MenuModule,
        PanelMenuModule,
        DialogModule,
        MessagesModule,
        ToastModule,
        CalendarModule,
        PanelModule,
        TableModule,
        DropdownModule,
        InputNumberModule,
        InputMaskModule,
        TabViewModule,
        FileUploadModule,
        InputTextareaModule,
        ScrollTopModule,
        ProgressSpinnerModule,
        MultiSelectModule,
        SkeletonModule,
        BlockUIModule,
        ContextMenuModule,
        TagModule,
    ],
    exports: [
        InputTextModule,
        CardModule,
        ButtonModule,
        SidebarModule,
        ToolbarModule,
        MenuModule,
        PanelMenuModule,
        DialogModule,
        MessagesModule,
        ToastModule,
        CalendarModule,
        PanelModule,
        TableModule,
        DropdownModule,
        InputNumberModule,
        InputMaskModule,
        TabViewModule,
        FileUploadModule,
        InputTextareaModule,
        ScrollTopModule,
        ProgressSpinnerModule,
        MultiSelectModule,
        SkeletonModule,
        BlockUIModule,
        ContextMenuModule,
        TagModule,
    ]
})
export class PrimeNgModule { }
