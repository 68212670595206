import { HttpClient } from "@angular/common/http";
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventSourceInput } from '@fullcalendar/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { first } from "rxjs/operators";
import {
  AgendaRecord,
  AgendaToCalendar,
} from '@data/models/agenda-record.model';
import { DateService } from '@core/services/agenda/date.service';
import { ApiService } from '@core/services/api/api.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgendaFacadeService {

  private diaryEntity: string = 'agenda';

  constructor(
    private _apiService: ApiService,
    private _dateService: DateService,
    private http: HttpClient,
  ) { }

  public getAgendaFilteredByDates(startDate: string, endDate: string, userId: number, callback: (response: any) => void): any {
    const params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('userId', userId);

    this.http.get(
      `${environment.baseUrl}/${environment.apiUrl}/${this.diaryEntity}/filtered-by-dates`, { params }
    ).subscribe((success: any) => {
      return callback({
        status: true,
        data: success
      });
    }, (error: any) => {
      return callback({
        status: false,
        data: error
      });
    });
  }

  markRecordAsCompleted(recordId: number): Observable<any> {
    return this._apiService.put(`${this.diaryEntity}/mark-as-completed/${recordId}`, null);
  }

  reSchedule(payload: { id: number; fecha: string }): Observable<any> {
    return this._apiService.post(`${this.diaryEntity}/re-schedule`, payload);
  }

  agendaToCalendarEvents(agenda: AgendaToCalendar[]): EventSourceInput {
    return agenda.map((record:any) => {
      const event = {
        id: record.id.toString(),
        casoNumero: record.historico.casoNumero,
        completada: record.completada,
        title: `${record.historico.casoNombre}`,
        start: `${record.fechaSeguimiento}+00:00`,
        backgroundColor: record.fechaCompletada && '#4A934A',
      };
      return event;
    });
  }

  changeDay(days: number, date: Date = new Date()): Date {
    return this._dateService.changeDayOnDate(days, date);
  }
}
