
<div class="card flex justify-content-center">
  <p-sidebar [(visible)]="sidebarVisible">
    <!-- header  -->
      <ng-template pTemplate="header">
        <div class="header-div">
          <img src="../../../../../assets/images/ant-icon.png" alt="">
          <h1>Menú principal</h1>
          <p>{{username}}</p>
        </div>
      </ng-template>
      <!-- content  -->
      <ng-template pTemplate="content">
        <p-panelMenu [model]="panelMenuItems"></p-panelMenu>
      </ng-template>
      <!-- footer  -->
      <ng-template pTemplate="footer">
        <p>Versión: {{version}}</p>
      </ng-template>
  </p-sidebar>
  <p-button (click)="sidebarVisible = true" icon="pi pi-bars"></p-button>
</div>