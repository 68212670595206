import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() { }

  public changeDayOnDate(days: number, date: Date = new Date()): Date {
    date.setDate(date.getDate() + days);
    return date;
  }
}
