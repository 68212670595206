import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FirebaseUser } from '@app/data/models/user.model';
import { IWorkspaceUser } from '@app/data/types';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public historicalEntity: string = 'historico';
  public caseEntity: string = 'caso';
  public agents: any = [];
  private firestore: Firestore = inject(Firestore);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  get currentAgentId() {
    const agent = this.agents.find((agent: any) => agent.webUserId === this.authService.currentUser?.uid);
    return  agent?.id;
  }

  async loadData() {
    this.agents = await firstValueFrom(this.getAgentsFromServer());
  }

  public async getAgents() {
    if (!this.agents.length) {
      this.agents = await firstValueFrom(this.getAgentsFromServer());
    }
    return Promise.resolve(this.agents);
  }

  public getAgentsFromServer() {
    return this.http.get(`${environment.baseUrl}/${environment.apiUrl}/user`);
  }

  public getFirebaseUsers() {
    return this.http.get<FirebaseUser[]>(`${environment.baseUrl}/${environment.apiUrl}/user/firebase-users`);
  }

  public getAllTenants() {
    return this.http.get(`${environment.baseUrl}/${environment.apiUrl}/user/tenants/all`);
  }

  public updateUser(user: IWorkspaceUser) {
    return this.http.put(`${environment.baseUrl}/${environment.apiUrl}/user`, user);
  }

  public getCustomToken(userId: string) {
    return this.http.get<{ token: string }>(`${environment.baseUrl}/${environment.apiUrl}/user/token/${userId}`);
  }

  public async impersonateUser(userId: string) {
    const data = await firstValueFrom(this.getCustomToken(userId));
    this.authService.impersonate(data.token);
  }

  public async getUserTenants(userId: string) {
    const docRef = doc(this.firestore, 'users', userId);
    const docSnap = await getDoc(docRef);
    return docSnap.data()?.['tenants'];
  }

  public setCurrentTenant(tenantId: string) {
    return this.http.post(`${environment.baseUrl}/${environment.apiUrl}/user/tenant/${tenantId}`, {});
  }
}
