import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'cedula'})
export class CedulaPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.length === 11) {
      return `${value.substring(0, 3)}-${value.substring(3, 10)}-${value.substring(10, 11)}`;
    }
    return value;
  }
}
