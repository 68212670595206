import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { finalize } from 'rxjs/operators';
import { AgendaToCalendar } from '@data/models/agenda-record.model';
import { AgendaFacadeService } from '@core/services/agenda/agenda-facade.service';
import esLocale from '@fullcalendar/core/locales/es';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements OnInit, OnChanges {
  @Input() agentId!: number;

  public loadingReSchedule: boolean = false;
  public loadingMarkAsCompleted: boolean = false;
  public loading: boolean = false;
  public displayEvent: boolean = false;
  public selectedEvent!: AgendaToCalendar | any;
  public scheduleDate: FormControl = new FormControl('');
  public tomorrow!: Date;
  // dayGridWeek
  // dayGridMonth
  public calendarOptions: CalendarOptions = {
    initialView: 'dayGridWeek',
    locale: esLocale,
    eventClick: this.handleClickEvent.bind(this),
    datesSet: this.handleMonthChange.bind(this),
    plugins: [dayGridPlugin, interactionPlugin],
  };

  private _agenda: AgendaToCalendar[] = [];
  private _startDate: string = '';
  private _endDate: string = '';

  constructor(
    private _agendaFacadeService: AgendaFacadeService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.tomorrow = this._agendaFacadeService.changeDay(1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['firstChange'] && this._startDate && this._endDate && this.agentId) {
      this.getAgenda();
    }
  }

  public handleMarkAsCompleted(): void {
    this.loadingMarkAsCompleted = true;
    this._agendaFacadeService
      .markRecordAsCompleted(this.selectedEvent?.id)
      .pipe(finalize(() => (this.loadingMarkAsCompleted = false)))
      .subscribe({
        next: () => {
          this.displayEvent = false;
          this.getAgenda();
          this.messageService.add({
            severity: 'success',
            summary: 'Completado',
            detail: 'El registro se ha marcado como completado exitosamente.'
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Se ha producido un error, por favor, inténtelo de nuevo.'
          });
        }
      });
  }

  public handleReSchedule(): void {
    this.loadingReSchedule = true;
    const payload = {
      id: this.selectedEvent.id,
      fecha: this.scheduleDate.value,
    };

    this._agendaFacadeService
      .reSchedule(payload)
      .pipe(finalize(() => (this.loadingReSchedule = false)))
      .subscribe({
        next: () => {
          this.displayEvent = false;
          this.getAgenda();
          this.messageService.add({
            severity: 'success',
            summary: 'Reagendado',
            detail: 'El registro se ha reagendado exitosamente.'
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Se ha producido un error, por favor, inténtelo de nuevo.'
          });
        }
      });
  }

  private handleMonthChange(date: any) {
    const {
      view: { currentStart, currentEnd },
    } = date;
    
    this._startDate = new Date(currentStart).toUTCString();
    this._endDate = new Date(currentEnd).toUTCString();

    this.getAgenda();
  }

  private getAgenda(): void {
    if (this._startDate && this._endDate && this.agentId) {
      setTimeout(() => this.loading = true, 0);
      this._agendaFacadeService.getAgendaFilteredByDates(this._startDate, this._endDate, this.agentId, (response: any) => {
        this.loading = false;
        if (response.status === true) {
          this._agenda = response.data;
          this.calendarOptions = {
            ...this.calendarOptions,
            events: this._agendaFacadeService.agendaToCalendarEvents(response.data),
          };
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Hubo un problema al obtener la info de la agenda. Intente mas tarde o contacte al administrador.'
          });
        }
      });
    }
  }

  private handleClickEvent({ event }: any): void {
    const { id } = event;
    this.selectedEvent = this._agenda.find(record => record.id === Number(id));
    if (this.selectedEvent) {
      this.displayEvent = true;
      this.scheduleDate.setValue('');
    }
  }
}
