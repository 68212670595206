import { Injectable, OnDestroy, OnInit, inject, signal } from '@angular/core';
import {
  Auth,
  user,
  User as FirebaseUser,
  authState,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  idToken,
  IdTokenResult,
  signInWithCustomToken,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Firestore, DocumentReference, collection, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { IWorkspaceUser } from '@data/types';
import { take } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import * as Sentry from "@sentry/angular";

export enum Role {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  USER = 'USER',
}

@Injectable({
  providedIn: 'root'
})

export class AuthService implements OnInit, OnDestroy {
  private auth: Auth = inject(Auth);
  private firestore: Firestore = inject(Firestore);
  private user: FirebaseUser | null = null;
  user$ = user(this.auth);
  userSubscription: Subscription;
  authState$ = authState(this.auth);
  authStateSubscription: Subscription;
  idToken$ = idToken(this.auth);
  idTokenSubscription: Subscription;

  role = Role.USER;
  hasTenantId: boolean = false;
  private _currentTenant = signal<string>('');
  currentTenant = this._currentTenant.asReadonly();
  tenants: { name: string, id: string }[] = [];
  userRef!: DocumentReference<any>;

  constructor(private router: Router) {
    const userProfileCollection = collection(this.firestore, 'users');

    this.userSubscription = this.user$.subscribe((user: FirebaseUser | null) => {
      //handle user state changes here. Note, that user will be null if there is no currently logged in user.
      this.user = user;
    });

    this.authStateSubscription = this.authState$.subscribe(async (user: FirebaseUser | null) => {
      //handle auth state changes here. Note, that user will be null if there is no currently logged in user.
      if (user) {
        this.userRef = doc(userProfileCollection, user.uid);
        var userDocument = await getDoc(this.userRef);
        var userData = userDocument.data();
        await this.loadTenants(userData.tenants);
        localStorage.setItem('defaultView', userData.defaultView);

        await user.getIdTokenResult().then((c: IdTokenResult) => {
          // this.admin = c.claims['admin'] || false;
          this.setCurrentTenant(c.claims['tenant_id'] as string);
          this.role = c.claims['role'] as Role || Role.USER;
          // this.hasTenantId = !!c.claims.tenantId;
          return this.updateUserData(user, c.token);
        });
        Sentry.setUser({
          id: user.uid,
          email: user.email || undefined,
        });
      } else {
        localStorage.setItem('user', '');
        Sentry.setUser(null);
      }
    });

    this.idTokenSubscription = this.idToken$.subscribe((token: string | null) => {
      //handle idToken changes here. Note, that user will be null if there is no currently logged in user.
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.authStateSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.idTokenSubscription.unsubscribe();
  }

  get displayName(): string {
    const user: IWorkspaceUser = JSON.parse(localStorage.getItem('user') || '{}');
    return user.displayName || '';
  }

  get email(): string {
    const user: IWorkspaceUser = JSON.parse(localStorage.getItem('user') || '{}');
    return user.email || '';
  }

  get uid(): string {
    const user: IWorkspaceUser = JSON.parse(localStorage.getItem('user') || '{}');
    return user.uid || '';
  }

  async getDefaultView(uid: string): Promise<string> {
    const document = doc(this.firestore, 'users', uid);
    return getDoc(document).then((doc: any) => {
      const defaultView = doc.data().defaultView;
      localStorage.setItem('defaultView', defaultView);
      return defaultView || 'home';
    });
  }

  get emailVerified(): boolean {
    const user: IWorkspaceUser = JSON.parse(localStorage.getItem('user') || '{}');
    return user.emailVerified || false;
  }

  get currentUser(): FirebaseUser | null {
    return this.user;
  }

  async loadTenants(tenants: string[]): Promise<void> {
    const tenantsCollection = collection(this.firestore, 'tenants');
    this.tenants = []; // Clear the array before adding new tenants
    const tenantPromises = tenants.map(async (tenantId) => {
      const tenantRef = doc(tenantsCollection, tenantId);
      const docSnap = await getDoc(tenantRef);
      const data = docSnap.data();
      this.tenants.push({ name: data?.['name'], id: docSnap.id });
    });
    await Promise.all(tenantPromises);
  }

  setCurrentTenant(tenantId: string): void {
    this._currentTenant.set(tenantId);
  }

  getTenantName(tenantId?: string): string {
    if (!tenantId) {
      tenantId = this.currentTenant();
    }
    const tenant = this.tenants?.find((t) => t.id === tenantId);
    return tenant?.name || '';
  }

  async login(email: string, password: string) {
    try {
      return await signInWithEmailAndPassword(this.auth, email, password)
        .then((res) => res)
        .catch((err: Error) => err);
    } catch (e: unknown) {
      console.error('Login Error! => ', (e as Error).message);
      return Promise.reject((e as Error).message);
    }
  }

  async logout() {
    await signOut(this.auth);
    localStorage.removeItem('user');
    await this.router.navigate(['login']);
  }

  async resetUserPassword(email: string) {
    try {
      await sendPasswordResetEmail(this.auth, email).then((res) => {
        alert('Se ha enviado el correo de verificación');
      }).catch((error) => {
        const e: Error = error;
        alert('No se ha podido enviar el correo de verificación. Error: ' + e.message);
      });

    } catch (e) {
      alert('Error! ' + (e as Error).message);
    }
  }

  async setNewPassword(newPassword: string) {
    try {
      if (this.user) {
        updatePassword(this.user, newPassword).then((res) => {
          alert('Se ha cambiado la contraseña.');
        }).catch((error) => {
          alert('Necesita iniciar sesión con sus nuevas credenciales!');
        });
      } else {
        alert('No se ha encontrado el usuario.');
      }
    } catch (e: unknown) {
      alert('Error! ' + (e as Error).message);
    }
  }

  get isLoggedIn(): boolean {
    return this.user !== null;
  }

  private updateUserData(user: FirebaseUser, token?: string) {
    const data: Partial<IWorkspaceUser> = {
      uid: user.uid,
      userName: user.email ?? '',
      email: user.email ?? '',
      displayName: user.displayName || (user.email ? user.email.split('@')[0] : ''),
      photoURL: user.photoURL ?? '',
      emailVerified: user.emailVerified,
      token: token || '' // Provide a default value for token
    };

    localStorage.setItem('user', JSON.stringify(data));
    return setDoc(this.userRef, data, { merge: true });
  }

  getUserToken(): Observable<string | null> {
    return this.idToken$.pipe(take(1)); // take(1) makes sure that the observable completes after emitting the first value
  }

  async refreshUserToken() {
    await this.user?.getIdTokenResult(true);
    window.location.reload();
  }

  async impersonate(token: string) {
    signInWithCustomToken(this.auth, token).then(() => {
      localStorage.setItem('user', '');
      window.location.reload();
    });
  }

}