import { CookieService } from 'ngx-cookie-service';
import { Cookies } from '@data/constants/cookies';
import { jwtDecode } from "jwt-decode";
import { IWorkspaceUser } from '@data/types';

export class TokenEntity {
  private token: string = '';
  private tenantIdValue: string = '';
  private cookieService: CookieService;

  constructor(cookieService: CookieService) {
    this.cookieService = cookieService;
    this.updateToken();
  }

  get value(): any{
    return this.token;
  }

  get decodeTenantId(): any {
    return atob(this.cookieService.get(Cookies.TENANTID))
  }

  get decodeTenantData(): any {
    let encodedString = this.cookieService.get(Cookies.TENANTDATA);
    let decodedString = atob(encodedString);
    return JSON.parse(decodedString);
  }

  get decodedValue(): any {
    if(this.token){
      return JSON.parse(atob(this.token));
    }else{
      return '';
    }
  }

  set value(data: IWorkspaceUser | null) {
    let jsonString = JSON.stringify(data);
    let encodedString = btoa(jsonString);
    this.cookieService.set(Cookies.TOKEN, encodedString);
    this.updateToken()
  }

  set tenantId(tenantId: string) {
    this.cookieService.set(Cookies.TENANTID, btoa(tenantId));
  }

  set actualTenant(data:any) {
    let jsonString = JSON.stringify(data);
    let encodedString = btoa(jsonString);
    this.cookieService.set(Cookies.TENANTDATA, encodedString);
  }

  public logOut(): void {
    this.delete();
    sessionStorage.clear();
  }

  private delete(): boolean {
    this.cookieService.deleteAll();
    return true;
  }

  private decodeToken(): any {
    this.updateToken();
    if (this.token) {
      return jwtDecode(this.token);
    }
    return null;
  }

  private updateToken() {
    this.token = this.cookieService.get(Cookies.TOKEN);
  }
}
