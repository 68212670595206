<p-toolbar [style]="{padding:'0.3rem'}">
    <div class="toolbar-left">
        <app-side-bar [panelMenuItems]="items" [username]="username"></app-side-bar>
        <span class="app-name cursor-pointer" routerLink="/home">Hormiguitas</span>
        <img class="cursor-pointer" src="../../../../assets/images/ant-icon.png" alt="logo" routerLink="/home">
    </div>
    <div class="toolbar-right">
        <div class="p-inputgroup" *ngIf="userTenants.length > 1">
            <span class="p-inputgroup-addon">
                <i class="pi pi-building"></i>
            </span>
            <p-dropdown 
                optionLabel="name" 
                optionValue="id" 
                styleClass="w-auto tenant-dropdown mr-2"
                [options]="userTenants" 
                [ngModel]="currentTenant()"
                (onChange)="onTenantChange($event)"
            >
            </p-dropdown>
        </div>
        <div class="min-w-max" *ngIf="userTenants.length === 1">
            <p-tag [value]="currentTenantName" styleClass="mr-2 text-xl"></p-tag>
        </div>
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="search" pInputText placeholder="Buscar" [ngModel]="casosSearchString"
            (search)="onBuscar($event)"
            (keydown.enter)="onBuscar($event)" />
        </span>
        <button pButton 
            type="button" 
            icon="pi pi-sign-out" 
            pTooltip="Salir" 
            tooltipPosition="bottom"
            class="ui-button-rounded ui-button-secondary w-full"
            (click)="logOut(false)" 
            label="Salir"
            iconPos="right"
        >
        </button>
    </div>
</p-toolbar>

<p-dialog header="Header" [(visible)]="visible" [style]="{ width: '50vw' }" [position]="'top'">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Cerrar sesión</span>
    </ng-template>
    <p>
        Seguro que deseas cerrar la sesión actual?
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="logOut(true)" label="Cerrar sesión" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>