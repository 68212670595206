import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PrimeNgModule } from './primeNg';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { SideBarComponent } from '@shared/components/side-bar/side-bar/side-bar.component';
import { AgendaComponent } from '@shared/components/agenda/agenda.component';
import {
  CedulaPipe
} from '@core/pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PrimeNgModule,
    FullCalendarModule
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    SideBarComponent,
    AgendaComponent,
    CedulaPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PrimeNgModule,
    FooterComponent,
    HeaderComponent,
    SideBarComponent,
    AgendaComponent,
    FullCalendarModule,
    CedulaPipe
  ]
})
export class SharedModule { }