import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  private static formatErrors(error: HttpErrorResponse) {
    if (error.status === 403) {
      console.log('No tiene permisos para acceder.');
    }
    return throwError(error.status);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(' https://ants-dev.azurewebsites.net/api/agenda/filtered-by-dates', { params })
      .pipe(catchError(ApiService.formatErrors));
  }

  post(path: string, data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/${this.apiUrl}/${path}`, data);
  }

  put(path: string, data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/${this.apiUrl}/${path}`, data);
  }

  delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${this.apiUrl}/${path}`, { params });
  }
}
