import { Component, inject } from '@angular/core';
import { AuthService } from '@app/core/services/auth/auth.service';
import appVersion from '../../../../version.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  private authService = inject(AuthService);
  currentUser = this.authService.currentUser;
  currentTenantName = this.authService.getTenantName();
  currentDate = new Date();
  version = appVersion.version;
}
