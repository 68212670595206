import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { Historico, ICaso, Telefono } from '@data/models/casos.model';
import { HttpParams } from '@angular/common/http';
import { IPaginatedResult } from '@data/models/paginated-result.model';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CasosService {
  public currentSearchString = '';
  public casosEntity: string = 'caso';
  public pagosEntity: string = 'pago';
  estatusCasos: any = [];
  sucursales: any = [];

  constructor(
    private http: HttpClient,
  ) {
  }

  async loadData() {
    this.estatusCasos = await firstValueFrom(this.getStatusListFromServer());
    this.sucursales = await this.getSucursalesList();
  }

  public getAllCasosLegacy(
    pageNumber: number, pageSize: number, search: string,
    sortField: string, sortOrder: number): Observable<IPaginatedResult<ICaso>> | any {
    // const params = new HttpParams()
    //   .set('pageSize', pageSize.toString())
    //   .set('pageNumber', pageNumber.toString())
    //   .set('sortField', sortField || '')
    //   .set('sortOrder', sortOrder.toString())
    //   .set('searchString', search || '');
    // return this.legacyApiService.get(`/casos`, params);
  }

  public async getSucursalesList() {
    if (!this.sucursales.length) {
      return this.getSucursalesFromServer();
    }
    return Promise.resolve(this.sucursales);
  }

  public getSucursalesFromServer() {
    return firstValueFrom(this.http.get(`${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/sucursales`));
  }

  public getFilteredCasos(pagination: any, callback: (response: any) => void): any {
    // pagination.sortField = pagination.sortField.charAt(0).toUpperCase() + pagination.sortField.slice(1);
    let params = new HttpParams()
      .set('rows', pagination.rows)
      .set('skipFirst', pagination.skipFirst)
      .set('sortField', pagination.sortField)
      .set('sortOrder', pagination.sortOrder);

      if (pagination.filteredEstatus?.length) {
        for (const idx of pagination.filteredEstatus.keys()) {
          params = params
            .append(`filteredStatus[${idx}]`, pagination.filteredEstatus[idx]);
        }
      }

      if (pagination.searchString)
        params = params.append('searchString', pagination.searchString);

      this.http.get(
        `${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/filtered`, { params }
      ).subscribe((success: any) => {
        callback({
          status: true,
          data: success
        });
      }, (error: any) => {
        callback({
          status: false,
          data: error
        });
      });
  }

  public getCasoByIdLegacy(casoId: number) {
    // return this.legacyApiService.get(`/casos/GetCaso?id=${casoId}`);
  }

  public createCase(body: any, callback: (response: any) => void): any {
    this.http.post(
      `${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}`,
      body
    ).subscribe((success: any) => {
      callback({
        status: true,
        data: success
      });
    }, (error: any) => {
      callback({
        status: false,
        data: error
      });
    });
  }

  public createPayment(body: any, callback: (response: any) => void): any {
    this.http.post(
      `${environment.baseUrl}/${environment.apiUrl}/${this.pagosEntity}`,
      body
    ).subscribe((success: any) => {
      callback({
        status: true,
        data: success
      });
    }, (error: any) => {
      console.log('error: ',error);
      callback({
        status: false,
        data: error
      });
    });
  }

  public updateCaso(caso: any, callback: (response: any) => void): any {
    this.http.put(
      `${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/${caso.id}`,
      caso
    ).subscribe((success: any) => {
      callback({
        status: true,
        data: success
      });
    }, (error: any) => {
      callback({
        status: false,
        data: error
      });
    });
  }

  public importCaseFromLegacyApi(casoId: number) {
    // this.getCasoByIdLegacy(casoId)
    //   .subscribe((c: ICaso) => {
    //     this.addCaso(c);
    //   });
  }

  public getCasosByAgentId(
    agenteId: number,
    first: string,
    rows: string,
    sortField: string,
    sortOrder: string,
    filter?: string,
    sucursal?: string,
    aging?: string
    ) {

    const params = new HttpParams()
      .set('first', first)
      .set('rows', rows)
      .set('sortField', sortField)
      .set('sortOrder', sortOrder)
      .set('filter', filter || '')
      .set('sucursal', sucursal || '')
      .set('aging', aging || '');

    return firstValueFrom(this.http.get(
      `${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/agente/${agenteId}`, { params }
    ));
  }

  public getResultadosList() {
    // return this.apiService.get('/historico/resultados-list');
  }

  public createTelefono(phone: any, callback: (response: any) => void): any {
    this.http.post(
      `${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/${phone.casoId}/telefono`,
      phone
    ).subscribe((success: any) => {
      callback({
        status: true,
        data: success
      });
    }, (error: any) => {
      callback({
        status: false,
        data: error
      });
    });
  }

  public updateTelefono(caseId: number, phoneId: number, phone: any, callback: (response: any) => void): any {
    this.http.put(
      `${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/${caseId}/telefono/${phoneId}`,
      phone
    ).subscribe((success: any) => {
      callback({
        status: true,
        data: success
      });
    }, (error: any) => {
      callback({
        status: false,
        data: error
      });
    });
  }

  public deleteTelefono(casoId: number, telefonoId: number, callback: (response: any) => void): any {
    this.http.delete(
      `${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/${casoId}/telefono/${telefonoId}`,
    ).subscribe((success: any) => {
      callback({
        status: true,
        data: success
      });
    }, (error: any) => {
      callback({
        status: false,
        data: error
      });
    });
  }

  public createHistorico(historico: Historico) {
    // return this.apiService.post(`/historico`, historico);
  }

  public anclarHistorico(id: number, value: boolean) {
    return firstValueFrom(this.http.put(`${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/anclar-historico/${id}/${value}`, { value }));
  }

  public async getEstatusList() {
    if (!this.estatusCasos.length) {
      this.estatusCasos = await firstValueFrom(this.getStatusListFromServer());
    }
    return Promise.resolve(this.estatusCasos);
  }

  public getStatusListFromServer(): Observable<any>  {
    return this.http.get(
      `${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/estatus`
    );
  }
  
  public getCasoById(casoId:number, callback: (response: any) => void): any {
    this.http.get(
      `${environment.baseUrl}/${environment.apiUrl}/${this.casosEntity}/${casoId}`
    ).subscribe((success: any) => {
      callback({
        status: true,
        data: success
      });
    }, (error: any) => {
      callback({
        status: false,
        data: error
      });
    });
  }

  public getTiposCasosList() {
    // return this.apiService.get('/caso/tipos');
  }

  public getCitiesList() {
    // return this.apiService.get('/caso/ciudades');
  }
}
