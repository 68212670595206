<footer class="app-footer">
    <div class="footer-content">
        <div class="footer-left">
            <div class="mr-2">
                <span class="pi pi-server"></span>
                <span class="footer-text">Versión: {{ version }}</span>
            </div>
            <div class="mr-2">
                <span class="pi pi-calendar"></span>
                <span class="footer-text">{{currentDate | date: 'dd/MM/yyyy'}}</span>
            </div>
            <div class="mr-2">
                <span class="pi pi-user"></span>
                <span class="footer-text">{{currentUser?.email}}</span>
            </div>
            <div class="mr-2">
                <span class="pi pi-building"></span>
                <span class="footer-text">{{currentTenantName}}</span>
            </div>
        </div>
        
        <div class="footer-right">
            <span class="footer-text">Powered By: 
                <a 
                    class="footer-link" 
                    href="https://codigofuente.do"
                    target="_blank"
                    >Código Fuente SRL
                </a>
            </span>
        </div>
    </div>
   
    <p-scrollTop></p-scrollTop>
</footer>