<div class="main-container">
  <p-blockUI [blocked]="loading">
    <i class="pi pi-spin pi-cog" style="font-size: 3rem"></i>
  </p-blockUI>
  <div class="main-container-one">    
    <full-calendar [options]="calendarOptions">
      <ng-template #eventContent let-arg>
        <div class="fc-content" [ngClass]="{'completed': arg.event.extendedProps.completada}">
          <div class="flex justify-content-between w-full">
            <div class="caso-numero">Caso No. {{arg.event.extendedProps.casoNumero}}</div>
            <div [ngClass]="{
                'text-green-400': arg.event.extendedProps.completada,
                'text-orange-400': !arg.event.extendedProps.completada,
                }" [title]="arg.event.extendedProps.completada ? 'Completada' : 'Pendiente'">
              <i class="pi pi-clock" [ngClass]="{ 
                'pi-check' : arg.event.extendedProps.completada,
                'pi-clock' : !arg.event.extendedProps.completada,
              }"></i>
            </div>
          </div>
          <div class="fc-title">{{ arg.event.title }}</div>
        </div>
      </ng-template>
    </full-calendar>
  </div>
</div>

<p-dialog header="Header" [modal]="true" [(visible)]="displayEvent" [style]="{ width: '35vw' }" [draggable]="false"
  [resizable]="false">
  <div class="data-div">

    <div class="data-div-item">
      <p class="data-div-item-span">Nombre Cliente</p>
      <p class="data-div-item-text">{{ selectedEvent?.historico?.casoNombre }}</p>
    </div>

    <div class="data-div-item">
      <p class="data-div-item-span">Resultado</p>
      <p class="data-div-item-text">{{ selectedEvent?.historico?.resultado }}</p>
    </div>

    <div class="data-div-item">
      <p class="data-div-item-span">Comentario</p>
      <p class="data-div-item-text">{{ selectedEvent?.historico?.resultado }}</p>
    </div>

    <div *ngIf="!selectedEvent?.fechaCompletada">
      <p class="font-bold">Fecha re-agendar:</p>
      <p-calendar id="scheduleDate" appendTo="body" [showIcon]="true" [style]="{ width: '100%' }"
        [formControl]="scheduleDate" [showTime]="true" [hideOnDateTimeSelect]="false" [minDate]="tomorrow">
      </p-calendar>
    </div>
    <div *ngIf="selectedEvent?.fechaCompletada">
      <p class="font-bold">Fecha Completada:</p>
      <p>{{ selectedEvent?.fechaCompletada | date }}</p>
    </div>
  </div>

  <ng-template pTemplate="header">
    <span class="font-bold">
      Detalle - Caso No. 
      <p-button
        [outlined]="true" 
        [label]="selectedEvent?.historico?.casoNumero" 
        size="small"
        styleClass="ml-1 caso-button"
        routerLink="/procesos/detalle/{{selectedEvent?.historico?.casoId}}">
      </p-button>
    </span>
  </ng-template>

  <ng-template pTemplate="footer">
    <div *ngIf="!selectedEvent?.fechaCompletada" class="flex justify-content-between">
      <p-button *ngIf="scheduleDate?.value" icon="pi pi-calendar" (onClick)="handleReSchedule()"
        [label]="loadingReSchedule ? 'Loading' : 'Re-agendar'" styleClass="p-button-text" [loading]="loadingReSchedule"
        [disabled]="loadingReSchedule || loadingMarkAsCompleted">
      </p-button>
      <p-button icon="pi pi-check" (onClick)="handleMarkAsCompleted()"
        [label]="loadingMarkAsCompleted ? 'Loading' : 'Completar'" styleClass="p-button-text" severity="success"
        [loading]="loadingMarkAsCompleted" [disabled]="loadingReSchedule || loadingMarkAsCompleted" [disabled]="true">
      </p-button>
    </div>
  </ng-template>
</p-dialog>