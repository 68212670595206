import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import appVersion from '../../../../../version.json';

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent implements OnInit {
  public readonly appVersion: string = appVersion.version;
  public sidebarVisible: boolean = false;
  public version = appVersion.version;
  @Input() panelMenuItems!:any;
  @Input() username:any = 'Sin nombre de usuario';

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }
}
